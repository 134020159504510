const alpha = 480;
const beta = 640;
const gamma = 720;
const delta = 960;
const epsilon = 1160;
const zeta = 1280;
const eta = 1440;
const theta = 1920;

const rootFontSize = 16;

module.exports = Object.entries({
  alpha,
  beta,
  gamma,
  delta,
  epsilon,
  zeta,
  eta,
  theta,
}).reduce(
  (acc, [name, value]) => ({
    ...acc,

    [name]: {
      px: value,
      em: value / rootFontSize,
    },
  }),
  {},
);
