import { render, staticRenderFns } from "./ShoppingCartItem.vue?vue&type=template&id=1921bbd7&"
import script from "./ShoppingCartItem.vue?vue&type=script&lang=js&"
export * from "./ShoppingCartItem.vue?vue&type=script&lang=js&"
import style0 from "./ShoppingCartItem.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseClickable: require('/home/runner/work/livelinor.dk/livelinor.dk/site/components/BaseClickable.vue').default,QuantityInput: require('/home/runner/work/livelinor.dk/livelinor.dk/site/components/QuantityInput.vue').default})
