//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BehanceIcon from '~/assets/img/icons/icon-behance.svg';
import DownloadIcon from '~/assets/img/icons/icon-download.svg';
import DribbbleIcon from '~/assets/img/icons/icon-dribbble.svg';
import InstagramIcon from '~/assets/img/icons/icon-instagram.svg';
import LinkedInIcon from '~/assets/img/icons/icon-linked-in.svg';
import TwitterIcon from '~/assets/img/icons/icon-twitter.svg';

export default {
  components: {
    BehanceIcon,
    DownloadIcon,
    DribbbleIcon,
    InstagramIcon,
    LinkedInIcon,
    TwitterIcon,
  },
};
