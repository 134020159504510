//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloudinaryImage from '@saulhardman/vue-cloudinary-image';
import { sentenceCase } from 'change-case';
import { debounce } from 'lodash';
import { mapActions, mapState } from 'vuex';

import { SHOPIFY_DEFAULT_VARIANT_TITLE } from '~/assets/js/constants';

export default {
  components: {
    CloudinaryImage,
  },

  filters: { sentenceCase },

  props: {
    image: {
      type: Object,

      required: true,
    },

    lineItemId: {
      type: String,

      required: true,
    },

    price: {
      type: Object,

      required: true,
    },

    productHandle: {
      type: String,

      required: true,
    },

    productTitle: {
      type: String,

      required: true,
    },

    quantity: {
      type: Number,

      required: true,
    },

    quantityAvailable: {
      type: Number,

      required: true,
    },

    variantTitle: {
      type: String,

      required: true,
    },

    variantId: {
      type: String,

      required: true,
    },
  },

  data() {
    return {
      inputValue: this.quantity,
    };
  },

  computed: {
    ...mapState('checkout', ['isLoading']),

    isDirty() {
      return this.quantity !== this.inputValue;
    },

    isDefaultVariantTitle() {
      return this.variantTitle === SHOPIFY_DEFAULT_VARIANT_TITLE;
    },

    productUrl() {
      return this.localePath({
        name: 'shop-products-handle',
        params: { handle: this.productHandle },
      });
    },

    totalAmount() {
      return this.quantity * this.price.amount;
    },

    totalPrice() {
      const { currencyCode } = this.price;
      const numberFormat = new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: currencyCode,
      });

      return numberFormat.format(this.totalAmount);
    },

    moreAvailable() {
      return this.quantityAvailable - this.quantity >= 1;
    },
  },

  watch: {
    inputValue(value) {
      if (value !== this.quantity) {
        this.debouncedUpdateLineItem({
          id: this.lineItemId,
          quantity: parseInt(value),
          variantId: this.variantId,
        });
      }
    },

    quantity(value) {
      this.inputValue = value;
    },
  },

  created() {
    this.debouncedUpdateLineItem = debounce(this.updateLineItem, 500);
  },

  methods: mapActions('checkout', ['updateLineItem']),
};
