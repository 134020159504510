import { toSanityLocale } from '~/assets/js/locales';
import { fetchFooter, fetchNavigation } from '~/assets/js/services/sanity';

const PAGE_LINK_TYPE = 'page-link';
const COLLECTION_LINK_TYPE = 'collection-link';

const parseLinks = (items, localePath) =>
  items.map((item) => {
    const { handle, type } = item;

    if (type === PAGE_LINK_TYPE) {
      return {
        ...item,

        to: localePath(handle),
      };
    }

    if (type === COLLECTION_LINK_TYPE) {
      return {
        ...item,

        to: localePath({
          name: 'shop-collections-handle',
          params: { handle },
        }),
      };
    }

    return item;
  });

export const state = () => ({
  footer: {},

  isShoppingCartVisible: false,

  navigation: [],
});

export const mutations = {
  setFooter(state, footer) {
    state.footer = footer;
  },

  setNavigation(state, navigation) {
    state.navigation = navigation;
  },

  setIsShoppingCartVisible(state, isShoppingCartVisible) {
    state.isShoppingCartVisible = isShoppingCartVisible;
  },
};

export const actions = {
  async fetchFooter({ commit }, locale) {
    const { linkLists, ...rest } = await fetchFooter({
      locale: toSanityLocale(locale),
    });

    commit('setFooter', {
      ...rest,

      linkLists: linkLists.map(({ items, ...rest }) => ({
        ...rest,

        items: parseLinks(items, this.app.localePath),
      })),
    });
  },

  async fetchNavigation({ commit }, locale) {
    const { items } = await fetchNavigation({
      locale: toSanityLocale(locale),
    });

    commit(
      'setNavigation',

      parseLinks(items, this.app.localePath),
    );
  },

  showShoppingCart({ commit }) {
    commit('setIsShoppingCartVisible', true);
  },

  hideShoppingCart({ commit }) {
    commit('setIsShoppingCartVisible', false);
  },

  toggleShoppingCart({ commit, state }) {
    commit('setIsShoppingCartVisible', !state.isShoppingCartVisible);
  },

  async nuxtServerInit({ dispatch }, { app }) {
    // NOTE: prevent preview mode from calling `nuxtServerInit`
    if (process.env.NODE_ENV === 'production' && process.client) {
      return;
    }

    const { locale } = app.i18n;

    await Promise.all([
      dispatch('fetchFooter', locale),
      dispatch('fetchNavigation', locale),
    ]);
  },
};
