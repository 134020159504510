export const SITE_TITLE = 'Liv Elinor Studio';
export const SITE_DESCRIPTION = `
  Liv Elinor is an Art Director & Illustrator creating positive change for companies through playful design solutions, brand identities and rainbow sprinkles
`;

export const SHOPIFY_DEFAULT_VARIANT_TITLE = 'Default Title';

export const BIG_MOUTH_AND_FRIENDS_COLLECTION_HANDLE = 'big-mouth-friends';
export const IMAGINARY_ICE_CREAMS_COLLECTION_HANDLE = 'imaginary-ice-creams';
export const KPUNS_COLLECTION_HANDLE = 'kpuns';
export const ORIGINAL_ARTWORK_COLLECTION_HANDLE = 'original-artwork';

export const PRIMARY_COLLECTIONS = {
  bigMouthAndFriends: BIG_MOUTH_AND_FRIENDS_COLLECTION_HANDLE,
  // imaginaryIcecreams: IMAGINARY_ICE_CREAMS_COLLECTION_HANDLE,
  kpuns: KPUNS_COLLECTION_HANDLE,
  originalArtwork: ORIGINAL_ARTWORK_COLLECTION_HANDLE,
};

export const PRIMARY_COLLECTION_HANDLES = Object.values(PRIMARY_COLLECTIONS);

export const FEATURED_COLLECTIONS = Object.entries(PRIMARY_COLLECTIONS).reduce(
  (acc, [alias, handle]) => ({
    ...acc,
    [alias]: `${handle}-featured`,
  }),
  {},
);

export const ORIGINAL_TAG_NAME = 'Original';

export const SIZE_PRODUCT_OPTION_NAME = 'Size';
export const MATERIAL_PRODUCT_OPTION_NAME = 'Material';

export const PRODUCT_OPTIONS_ORDER = [
  SIZE_PRODUCT_OPTION_NAME,
  MATERIAL_PRODUCT_OPTION_NAME,
];

export const META_DESCRIPTION_MAX_LENGTH = 160;
