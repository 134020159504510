//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SITE_TITLE } from '~/assets/js/constants';
import MenuIcon from '~/assets/img/icons/icon-menu.svg';
import StudioLogo from '~/assets/img/liv-elinor-studio-logo.svg';

export default {
  components: {
    MenuIcon,
    StudioLogo,
  },

  data: () => ({ SITE_TITLE }),

  methods: {
    handleShowNavigationButtonClick() {
      this.$refs.shopNavigation.open();
    },
  },
};
