import locales from '~/assets/js/locales';
import sanity from '~/assets/js/sanity-client';

const navigationItemsFragment = /* groq */ `
  items[] {
    _type == 'custom-link' => {
      'type': _type,
      href,
      label,
      title
    },

    _type == 'page-link' => {
      'type': _type,
      'handle': page->handle.current,
      'slug': page->content[$locale].slug.current,
      label,
      title,
    },

    _type == 'collection-link' => {
      'type': _type,
      handle,
      label,
      title,
    }
  }
`;

const customImageFragment = /* groq */ `
  altText,

  ...asset->{
    mimeType,
    url,

    ...metadata {
      ...dimensions {
        width,
        height
      }
    }
  }
`;

export const fetchIndexData = (params = { locale: 'en' }) =>
  sanity.fetch(
    /* groq */ `
    *[_id == 'homepage'][0] {
      ...content[$locale] {
        title,
        description,

        heroImage {
          ${customImageFragment}
        },

        meta {
          title,
          description,
          image {
            ${customImageFragment}
          }
        }
      }
    }
  `,
    params,
  );

export const fetchNavigation = (params = { locale: 'en' }) =>
  sanity.fetch(
    /* groq */ `
      *[_type == 'navigation' && handle.current == 'primary-navigation'][0] {
        ...content[$locale] {
          ${navigationItemsFragment}
        }
      }
    `,
    params,
  );

export const fetchAuxiliaryPageSlugs = () =>
  sanity.fetch(/* groq */ `
      *[_type == 'page'] {
        'handle': handle.current,

        content {
          ${locales
            .map(
              ({ sanityCode: locale }) => `${locale} { 'slug': slug.current }`,
            )
            .join(',')}
        }
      }
    `);

export const fetchPageByHandle = (handle, params = { locale: 'en' }) =>
  sanity.fetch(
    /* groq */ `
      *[_type == 'page' && handle.current == $handle][0] {
        'handle': handle.current,

        ...content[$locale] {
          description,
          content[] {
            ...,

            _type == 'custom-image' => {
              ${customImageFragment}
            },

            markDefs[] {
              ...,

              _type == 'external-link' => {
                href,
                title
              },

              _type == 'internal-link' => {
                'handle': item->handle.current,
                title
              }
            }
          },
          'slug': slug.current,
          title,

          meta {
            title,
            description,
            image {
              ${customImageFragment}
            }
          }
        }
      }
    `,
    { handle, ...params },
  );

export const fetchFooter = (params = { locale: 'en' }) =>
  sanity.fetch(
    /* groq */ `
      *[_id == 'footer'][0] {
        ...content[$locale] {
          about,

          linkLists[] {
            title,

            ...navigation -> {
              ...content[$locale] {
                ${navigationItemsFragment}
              }
            }
          },
        }
      }
    `,
    params,
  );
