import { paramCase } from 'change-case';

import {
  BIG_MOUTH_AND_FRIENDS_COLLECTION_HANDLE,
  KPUNS_COLLECTION_HANDLE,
  ORIGINAL_ARTWORK_COLLECTION_HANDLE,
  PRIMARY_COLLECTIONS,
} from '~/assets/js/constants';

let shopifyClient;

export const setShopifyClient = (client) => (shopifyClient = client);

const PRODUCTS_PER_PAGE = 100;
const PRODUCT_TYPES_PER_PAGE = 20;

const handleCheckoutUserErrors = (errors = []) => {
  if (errors.length === 0) {
    return;
  }

  errors.forEach((error) => console.error(error));

  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!window.Sentry) {
    console.warn('Sentry not found.');

    return;
  }

  const { Sentry } = window;

  // NOTE: could add checkout data here
  Sentry.captureException(new Error('Shopify Checkout Error'), {
    extra: errors,
  });
};

const mungeProduct = ({ collections, images, variants, ...rest }) => ({
  ...(collections
    ? { collections: collections.edges.map(({ node }) => node) }
    : {}),
  ...(images ? { images: images.edges.map(({ node }) => node) } : {}),
  ...(variants ? { variants: variants.edges.map(({ node }) => node) } : {}),

  ...rest,
});

const mungeProducts = (products) =>
  products.edges.map(({ node: product }) => mungeProduct(product));

const shopifyImageFragment = /* GraphQL */ `
  fragment shopifyImage on Image {
    altText
    id
    height
    originalSrc
    width
  }
`;

const collectionPreviewFragment = /* GraphQL */ `
  fragment collectionPreview on Collection {
    description
    descriptionHtml
    handle
    id
    title

    image {
      altText
      id
      height
      originalSrc
      width
    }
  }
`;

const productPreviewFragment = /* GraphQL */ `
  fragment productPreview on Product {
    availableForSale
    handle
    id
    title

    images(first: 1) {
      edges {
        node {
          ...shopifyImage
        }
      }
    }

    priceRange {
      maxVariantPrice {
        amount
      }

      minVariantPrice {
        amount
        currencyCode
      }
    }
  }

  ${shopifyImageFragment}
`;

const productDetailFragment = /* GraphQL */ `
  fragment productDetail on Product {
    availableForSale
    id
    handle
    description
    descriptionHtml
    productType
    tags
    title
    totalInventory

    collections(first: 5) {
      edges {
        node {
          id
          handle
          title
        }
      }
    }

    images(first: 30) {
      edges {
        node {
          ...shopifyImage
        }
      }
    }

    options(first: 10) {
      id
      name
      values
    }

    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
    }

    variants(first: 10) {
      edges {
        node {
          availableForSale
          id
          quantityAvailable

          price: priceV2 {
            amount
            currencyCode
          }

          selectedOptions {
            name
            value
          }
        }
      }
    }
  }

  ${shopifyImageFragment}
`;

const checkoutFragment = /* GraphQL */ `
  fragment checkout on Checkout {
    completedAt
    id
    ready
    webUrl

    lineItems(first: 10) {
      edges {
        node {
          id
          quantity
          title

          variant {
            id
            quantityAvailable
            title

            image {
              ...shopifyImage
            }

            price: priceV2 {
              amount
              currencyCode
            }

            product {
              handle
            }
          }
        }
      }
    }

    totalPrice: totalPriceV2 {
      amount
      currencyCode
    }
  }

  ${shopifyImageFragment}
`;

const checkoutUserErrorsFragment = /* GraphQL */ `
  fragment checkoutUserErrors on CheckoutUserError {
    code
    field
    message
  }
`;

export const fetchIndexData = async () => {
  const COLLECTIONS = {
    bigMouthAndFriends: BIG_MOUTH_AND_FRIENDS_COLLECTION_HANDLE,
    kpuns: KPUNS_COLLECTION_HANDLE,
    originalArtwork: ORIGINAL_ARTWORK_COLLECTION_HANDLE,
  };

  const query = /* GraphQL */ `
    query index {
      ${Object.entries(COLLECTIONS).map(
        ([alias, handle]) => `
          ${alias}: collectionByHandle(handle: "${handle}") {
            ...collectionPreview,
          }

          ${alias}Featured: collectionByHandle(handle: "${handle}-featured") {
            products(first: 4) {
              edges {
                node {
                  ...productPreview
                }
              }
            }
          }
        `,
      )}

      newStuff: products(first: 4, reverse: true, sortKey: CREATED_AT) {
        edges {
          node {
            ...productPreview
          }
        }
      }
    }

    ${collectionPreviewFragment}
    ${productPreviewFragment}
  `;

  const { newStuff, ...rest } = await shopifyClient({
    query,
  });

  const collections = Object.keys(COLLECTIONS).reduce((acc, alias) => {
    const featuredCollection = rest[`${alias}Featured`];

    if (!featuredCollection) {
      return {
        ...acc,

        [alias]: rest[alias],
      };
    }

    const { products } = featuredCollection;

    return {
      ...acc,

      [alias]: {
        ...rest[alias],

        products: mungeProducts(products),
      },
    };
  }, {});

  return {
    ...collections,

    newStuff: mungeProducts(newStuff),
  };
};

export const fetchProductIndexData = async () => {
  const query = /* GraphQL */ `
    query productIndex($productsPerPage: Int!, $productTypesPerPage: Int!) {
      ${Object.entries(PRIMARY_COLLECTIONS).map(
        ([alias, handle]) =>
          `${alias}: collectionByHandle(handle: "${handle}") { ...collectionPreview }`,
      )}

      productTypes(first: $productTypesPerPage) {
        edges {
          node
        }
      }

      products(first: $productsPerPage, sortKey: TITLE) {
        edges {
          node {
            ...productPreview
          }
        }
      }
    }

    ${collectionPreviewFragment}
    ${productPreviewFragment}
  `;

  const { products, productTypes, ...collections } = await shopifyClient({
    query,

    variables: {
      productsPerPage: PRODUCTS_PER_PAGE,
      productTypesPerPage: PRODUCT_TYPES_PER_PAGE,
    },
  });

  return {
    collections: Object.entries(collections)
      .filter(([, value]) => value)
      .map(([, value]) => value),

    productTypes: productTypes.edges.map(({ node }) => ({
      title: node,
      slug: paramCase(node),
    })),

    products: mungeProducts(products),
  };
};

export const fetchCollectionIndexDataByHandle = async (handle) => {
  const query = /* GraphQL */ `
    query collectionIndex($handle: String!, $productsPerPage: Int!, $productTypesPerPage: Int!) {
      ${Object.entries(PRIMARY_COLLECTIONS).map(
        ([alias, handle]) =>
          `${alias}: collectionByHandle(handle: "${handle}") { ...collectionPreview }`,
      )}

      productTypes(first: $productTypesPerPage) {
        edges {
          node
        }
      }

      collection: collectionByHandle(handle: $handle) {
        description
        handle
        title

        products(first: $productsPerPage, sortKey: TITLE) {
          edges {
            node {
              ...productPreview
            }
          }
        }
      }
    }

    ${collectionPreviewFragment}
    ${productPreviewFragment}
  `;

  const {
    collection,
    productTypes,

    ...collections
  } = await shopifyClient({
    query,

    variables: {
      handle,
      productsPerPage: PRODUCTS_PER_PAGE,
      productTypesPerPage: PRODUCT_TYPES_PER_PAGE,
    },
  });

  if (collection === null) {
    throw new Error('404 Not Found');
  }

  const { products, title, description, image } = collection;

  return {
    collections: Object.entries(collections)
      .filter(([, value]) => value)
      .map(([, value]) => value),

    productTypes: productTypes.edges.map(({ node }) => ({
      title: node,
      slug: paramCase(node),
    })),

    products: mungeProducts(products),

    description,
    image,
    title,
  };
};

export const fetchProductTypeIndexDataBySlug = async (slug) => {
  const query = /* GraphQL */ `
    query productTypeIndex($productTypesPerPage: Int!, $productsPerPage: Int!, $productQuery: String!) {
      ${Object.entries(PRIMARY_COLLECTIONS).map(
        ([alias, handle]) =>
          `${alias}: collectionByHandle(handle: "${handle}") { ...collectionPreview }`,
      )}

      productTypes(first: $productTypesPerPage) {
        edges {
          node
        }
      }

      products(first: $productsPerPage, query: $productQuery, sortKey: TITLE) {
        edges {
          node {
            ...productPreview
          }
        }
      }
    }

    ${collectionPreviewFragment}
    ${productPreviewFragment}
  `;

  const { products, productTypes, ...collections } = await shopifyClient({
    query,

    variables: {
      productQuery: `product_type:${slug}`,
      productsPerPage: PRODUCTS_PER_PAGE,
      productTypesPerPage: PRODUCT_TYPES_PER_PAGE,
    },
  });

  const mungedProductTypes = productTypes.edges.map(({ node: title }) => ({
    title,
    slug: paramCase(title),
  }));

  if (!mungedProductTypes.some(({ slug: otherSlug }) => slug === otherSlug)) {
    throw new Error('404 Not Found');
  }

  return {
    collections: Object.entries(collections)
      .filter(([, value]) => value)
      .map(([, value]) => value),

    productTypes: mungedProductTypes,

    products: mungeProducts(products),
  };
};

export const fetchProductDataByHandle = async (handle) => {
  const query = /* GraphQL */ `
    query product($handle: String!) {
      product: productByHandle(handle: $handle) {
        ...productDetail
      }
    }

    ${productDetailFragment}
  `;

  const { product } = await shopifyClient({
    query,

    variables: {
      handle,
    },
  });

  return mungeProduct(product);
};

export const fetchRelatedProductData = async ({
  collectionHandle = null,
  productType,
}) => {
  if (collectionHandle === null) {
    const query = /* GraphQL */ `
      query relatedProducts($productCount: Int!, $productTypeQuery: String!) {
        byProductType: products(
          first: $productCount
          query: $productTypeQuery
        ) {
          edges {
            node {
              ...productPreview
            }
          }
        }
      }

      ${productPreviewFragment}
    `;

    const { byProductType } = await shopifyClient({
      query,

      variables: {
        productCount: 5,
        productTypeQuery: `product_type:${productType}`,
      },
    });

    return {
      byProductType: mungeProducts(byProductType),
    };
  }

  const query = /* GraphQL */ `
    query relatedProducts(
      $collectionHandle: String!
      $productCount: Int!
      $productTypeQuery: String!
    ) {
      byCollection: collectionByHandle(handle: $collectionHandle) {
        products(first: $productCount) {
          edges {
            node {
              ...productPreview
            }
          }
        }
      }

      byProductType: products(first: $productCount, query: $productTypeQuery) {
        edges {
          node {
            ...productPreview
          }
        }
      }
    }

    ${productPreviewFragment}
  `;

  const { byCollection, byProductType } = await shopifyClient({
    query,

    variables: {
      collectionHandle,
      productCount: 5,
      productTypeQuery: `product_type:${productType}`,
    },
  });

  return {
    byCollection: mungeProducts(byCollection.products),
    byProductType: mungeProducts(byProductType),
  };
};

export const createCheckout = async (lineItems) => {
  const input = { lineItems };
  const query = /* GraphQL */ `
    mutation checkoutCreate($input: CheckoutCreateInput!) {
      checkoutCreate(input: $input) {
        checkout {
          ...checkout
        }

        checkoutUserErrors {
          ...checkoutUserErrors
        }
      }
    }

    ${checkoutFragment}
    ${checkoutUserErrorsFragment}
  `;

  const {
    checkoutCreate: { checkout, checkoutUserErrors = [] },
  } = await shopifyClient({
    query,

    variables: {
      input,
    },
  });

  handleCheckoutUserErrors(checkoutUserErrors);

  return {
    ...checkout,

    lineItems: checkout.lineItems.edges.map(({ node }) => node),
  };
};

export const fetchCheckout = async (checkoutId) => {
  const query = /* GraphQL */ `
    query fetchCheckout($checkoutId: ID!) {
      checkout: node(id: $checkoutId) {
        ...checkout
      }
    }

    ${checkoutFragment}
  `;

  const { checkout, checkoutUserErrors = [] } = await shopifyClient({
    query,

    variables: {
      checkoutId,
    },
  });

  handleCheckoutUserErrors(checkoutUserErrors);

  return {
    ...checkout,

    lineItems: checkout.lineItems.edges.map(({ node }) => node),
  };
};

export const removeCheckoutLineItems = async (checkoutId, lineItemIds) => {
  const query = /* GraphQL */ `
    mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
      checkoutLineItemsRemove(
        checkoutId: $checkoutId
        lineItemIds: $lineItemIds
      ) {
        checkout {
          ...checkout
        }

        checkoutUserErrors {
          ...checkoutUserErrors
        }
      }
    }

    ${checkoutFragment}
    ${checkoutUserErrorsFragment}
  `;

  const {
    checkoutLineItemsRemove: { checkout, checkoutUserErrors = [] },
  } = await shopifyClient({
    query,

    variables: {
      checkoutId,
      lineItemIds,
    },
  });

  handleCheckoutUserErrors(checkoutUserErrors);

  return {
    ...checkout,

    lineItems: checkout.lineItems.edges.map(({ node }) => node),
  };
};

export const addCheckoutLineItems = async (checkoutId, lineItems) => {
  const query = /* GraphQL */ `
    mutation checkoutLineItemsAdd(
      $checkoutId: ID!
      $lineItems: [CheckoutLineItemInput!]!
    ) {
      checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
        checkout {
          ...checkout
        }

        checkoutUserErrors {
          ...checkoutUserErrors
        }
      }
    }

    ${checkoutFragment}
    ${checkoutUserErrorsFragment}
  `;

  const {
    checkoutLineItemsAdd: { checkout, checkoutUserErrors = [] },
  } = await shopifyClient({
    query,

    variables: {
      checkoutId,
      lineItems,
    },
  });

  handleCheckoutUserErrors(checkoutUserErrors);

  return {
    ...checkout,

    lineItems: checkout.lineItems.edges.map(({ node }) => node),
  };
};

export const updateCheckoutLineItems = async (checkoutId, lineItems) => {
  const query = /* GraphQL */ `
    mutation checkoutLineItemsUpdate(
      $checkoutId: ID!
      $lineItems: [CheckoutLineItemUpdateInput!]!
    ) {
      checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
        checkout {
          ...checkout
        }

        checkoutUserErrors {
          ...checkoutUserErrors
        }
      }
    }

    ${checkoutFragment}
    ${checkoutUserErrorsFragment}
  `;

  const {
    checkoutLineItemsUpdate: { checkout, checkoutUserErrors = [] },
  } = await shopifyClient({
    query,

    variables: {
      checkoutId,
      lineItems,
    },
  });

  handleCheckoutUserErrors(checkoutUserErrors);

  return {
    ...checkout,

    lineItems: checkout.lineItems.edges.map(({ node }) => node),
  };
};

export const fetchProductCatalogue = async () => {
  const query = /* GraphQL */ `
    query productCatalogue($productsPerPage: Int!) {
      products(first: $productsPerPage, sortKey: TITLE) {
        edges {
          node {
            description
            handle
            productType
            title

            images(first: 20) {
              edges {
                node {
                  originalSrc
                }
              }
            }

            collections(first: 10) {
              edges {
                node {
                  handle
                  title
                }
              }
            }

            variants(first: 50) {
              edges {
                node {
                  availableForSale
                  currentlyNotInStock
                  sku
                  title

                  price: priceV2 {
                    amount
                    currencyCode
                  }

                  image {
                    originalSrc
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { products } = await shopifyClient({
    query,

    variables: {
      productsPerPage: PRODUCTS_PER_PAGE,
    },
  });

  return mungeProducts(products);
};
