//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { sentenceCase } from 'change-case';

import breakpoints from '~/assets/js/breakpoints';
import { observeMediaQueries } from '~/assets/js/media-queries';
import locales from '~/assets/js/locales';
import BigMouthFace from '~/assets/img/big-mouth-face.svg';
import CloseIcon from '~/assets/img/icons/icon-close.svg';

const { gamma } = breakpoints;

export default {
  filters: {
    sentenceCase,
  },

  components: {
    BigMouthFace,
    CloseIcon,
  },

  data() {
    return {
      locales,

      isHydrated: false,
      isOpen: false,
      isGamma: false,
    };
  },

  computed: {
    ...mapState(['navigation']),

    currentLocale() {
      return this.$i18n.locale;
    },
  },

  watch: {
    $route() {
      if (!this.isGamma) {
        this.close();
      }
    },
  },

  mounted() {
    if (!('matchMedia' in window)) {
      this.isHydrated = true;

      return;
    }

    this.$on('gamma', this.handleGammaMediaQueryListChange);

    this.mediaQueryLists = observeMediaQueries({ gamma }, (name, event) =>
      this.$emit(name, event),
    );

    this.isHydrated = true;
  },

  beforeDestroy() {
    if (this.isOpen) {
      clearAllBodyScrollLocks();
    }

    if (!('matchMedia' in window)) {
      return;
    }

    Object.values(this.mediaQueryLists).forEach(([, removeEventListener]) =>
      removeEventListener(),
    );

    this.mediaQueryLists = null;

    this.$off();
  },

  methods: {
    open() {
      this.isOpen = true;

      if (!this.isGamma) {
        disableBodyScroll(this.$el);
      }
    },

    close() {
      this.isOpen = false;

      if (!this.isGamma) {
        clearAllBodyScrollLocks();
      }
    },

    toggle() {
      if (this.isOpen) {
        this.close();

        return;
      }

      this.open();
    },

    handleGammaMediaQueryListChange({ matches }) {
      this.isGamma = matches;

      if (this.isOpen && !this.isGamma) {
        this.close();

        return;
      }

      if (!this.isOpen && this.isGamma) {
        this.open();
      }
    },
  },
};
