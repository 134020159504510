//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import { capitalCase, sentenceCase } from 'change-case';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import breakpoints from '~/assets/js/breakpoints';
import CloseIcon from '~/assets/img/icons/icon-close.svg';
import JungshookRabbit from '~/assets/img/jungshook-rabbit.svg';
import ShoppingCartItem from '~/components/ShoppingCartItem.vue';

const { gamma } = breakpoints;

export default {
  components: {
    CloseIcon,
    JungshookRabbit,
    ShoppingCartItem,
  },

  filters: {
    capitalCase,
    sentenceCase,
  },

  data: () => ({ isGamma: false }),

  computed: {
    ...mapState(['isShoppingCartVisible']),
    ...mapState('checkout', ['checkout', 'isLoading']),

    totalPrice() {
      const { amount, currencyCode } = this.checkout.totalPrice;
      const numberFormat = new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: currencyCode,
      });

      return numberFormat.format(amount);
    },

    isActive() {
      const { id, completedAt, lineItems } = this.checkout;

      return id !== null && completedAt === null && lineItems.length > 0;
    },
  },

  mounted() {
    this.restoreCheckout();

    this.unsubscribeFromActions = this.$store.subscribeAction({
      after: this.handleToggleShoppingCartAction,
    });

    this.$on('gamma', this.handleGammaMediaQueryListChange);

    this.mediaQueryLists = Object.entries({ gamma }).reduce(
      (acc, [name, { em }]) => {
        const mediaQueryList = matchMedia(`(min-width: ${em}em)`);
        const handler = (event) => this.$emit(name, event);

        if ('addEventListener' in mediaQueryList) {
          mediaQueryList.addEventListener('change', handler);
        } else {
          mediaQueryList.addListener(handler);
        }

        handler(mediaQueryList);

        return {
          ...acc,

          [name]: [
            mediaQueryList,
            () => {
              if ('removeEventListener' in mediaQueryList) {
                mediaQueryList.removeEventListener('change', handler);
              } else {
                mediaQueryList.removeListener(handler);
              }
            },
          ],
        };
      },
      {},
    );
  },

  beforeDestroy() {
    this.unsubscribeFromActions();

    this.$off();

    if (this.isShoppingCartVisible) {
      clearAllBodyScrollLocks();
    }
  },

  methods: {
    ...mapActions(['toggleShoppingCart']),
    ...mapActions('checkout', ['clearCheckout', 'restoreCheckout']),

    handleToggleShoppingCartAction({ type }) {
      if (type === 'toggleShoppingCart') {
        if (this.isShoppingCartVisible && !this.isGamma) {
          disableBodyScroll(this.$el);

          return;
        }

        clearAllBodyScrollLocks();
      }
    },

    handleGammaMediaQueryListChange({ matches }) {
      this.isGamma = matches;

      if (!this.isShoppingCartVisible) {
        return;
      }

      if (this.isGamma) {
        clearAllBodyScrollLocks();

        return;
      }

      disableBodyScroll(this.$el);
    },
  },
};
