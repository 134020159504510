//
//
//
//
//
//
//
//
//
//
//

import { sentenceCase } from 'change-case';

import { SITE_TITLE } from '~/assets/js/constants';

export default {
  filters: {
    sentenceCase,
  },

  layout({ route }) {
    if (route.path.startsWith('/shop/')) {
      return 'shop';
    }
  },

  props: {
    error: {
      type: Object,

      required: true,
    },
  },

  head() {
    return {
      title: `${this.title} | ${SITE_TITLE}`,
    };
  },

  computed: {
    title() {
      const { statusCode } = this.error;

      if (statusCode === 404) {
        return 'Page Not Found';
      }

      return 'An Error Occurred';
    },

    isShop() {
      return this.$route.path.startsWith('/shop/');
    },
  },
};
