var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"c-shop-footer [ text-center ][ beta:text-left ][ zeta:text-1 ]"},[_c('div',{staticClass:"[ order-4 flex flex-col flex-no-wrap items-center ]"},[_c('BaseClickable',{staticClass:"c-shop-footer__back-to-top-button [ inline-flex flex-col flex-no-wrap items-center mt-8 p-4 font-bold text-4 ]",on:{"click":_vm.scrollToTop}},[_c('ArrowIcon',{staticClass:"c-shop-footer__back-to-top-arrow-icon",attrs:{"width":"14","height":"10"}}),_vm._v(" "),_c('span',{staticClass:"[ mt-4 ]"},[_vm._v("\n        "+_vm._s(_vm._f("sentenceCase")(_vm.$t('back-to-top')))+"\n      ")])],1),_vm._v(" "),_c('BigMouth',{staticClass:"[ mx-auto ]",attrs:{"width":"224","height":"136","preserveAspectRatio":"xMidYMin slice"}})],1),_vm._v(" "),_c('div',{staticClass:"bg-pink-10"},[_c('div',{staticClass:"c-shop-footer__body o-container [ beta:py-8 ][ gamma:py-10 ]"},[_c('div',{staticClass:"c-shop-footer__about s-shop-footer-about [ p-8 ][ beta:p-0 ]"},[_c('BlockContent',{attrs:{"blocks":_vm.about}})],1),_vm._v(" "),_vm._l((_vm.linkLists),function(ref){
var items = ref.items;
var title = ref.title;
return _c('div',{key:title,staticClass:"c-shop-footer__section [ w-3/5 mx-auto py-8 ][ beta:w-full beta:py-0 beta:px-6 ]"},[_c('h5',{staticClass:"[ m-0 mb-4 text-1 ]",domProps:{"textContent":_vm._s(title)}}),_vm._v(" "),_c('ol',{staticClass:"[ list-none flex flex-col flex-no-wrap items-center justify-center m-0 p-0 leading-relaxed ][ beta:items-start ]"},_vm._l((items),function(ref){
var handle = ref.handle;
var href = ref.href;
var label = ref.label;
var to = ref.to;
return _c('li',{key:handle || href},[_c('BaseClickable',_vm._b({},'BaseClickable',{ to: to, href: href },false),[_vm._v("\n              "+_vm._s(label)+"\n            ")])],1)}),0)])})],2)]),_vm._v(" "),_c('div',{staticClass:"[ bg-pink-20 ]"},[_c('div',{staticClass:"c-shop-footer__meta o-container [ flex flex-col flex-no-wrap justify-center py-8 ][ beta:flex-row beta:justify-between ]"},[_c('div',{staticClass:"[ flex flex-row flex-no-wrap items-center justify-center mb-5 ][ beta:mb-0 ]"},[_c('VisaIcon',{staticClass:"[ rounded-sm ]",attrs:{"width":"35","height":"22.5"}}),_vm._v(" "),_c('AmexIcon',{staticClass:"[ ml-4 rounded-sm ]",attrs:{"width":"35","height":"22.5"}}),_vm._v(" "),_c('MastercardIcon',{staticClass:"[ ml-4 rounded-sm ]",attrs:{"width":"35","height":"22.5"}}),_vm._v(" "),_c('MaestroIcon',{staticClass:"[ ml-4 rounded-sm ]",attrs:{"width":"35","height":"22.5"}}),_vm._v(" "),_c('ApplePayIcon',{staticClass:"[ ml-4 rounded-sm ]",attrs:{"width":"35","height":"22.5"}}),_vm._v(" "),_c('GooglePayIcon',{staticClass:"[ ml-4 rounded-sm ]",attrs:{"width":"35","height":"22.5"}})],1),_vm._v(" "),_c('p',{staticClass:"[ m-0 ]"},[_vm._v("© Copyright "+_vm._s(_vm.currentYear)+" Liv Elinor")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }