import { META_DESCRIPTION_MAX_LENGTH, SITE_TITLE } from '~/assets/js/constants';
import { truncate } from '~/assets/js/utilities';

export const metaTag = ({ name, property, content }) => ({
  hid: name ?? property,
  content,
  ...(name ? { name } : {}),
  ...(property ? { property } : {}),
});

const openGraphImageMeta = ({ url, width, height, mimeType }) =>
  [
    metaTag({
      property: 'og:image:url',
      content: url,
    }),

    metaTag({
      property: 'og:image:secure_url',
      content: url,
    }),

    metaTag({
      name: 'twitter:image',
      content: url,
    }),

    metaTag({
      property: 'og:image:type',
      content: mimeType,
    }),

    width &&
      metaTag({
        property: 'og:image:width',
        content: width,
      }),

    height &&
      metaTag({
        property: 'og:image:height',
        content: height,
      }),
  ].filter(Boolean);

export const pageTitle = (title) => `${title} | ${SITE_TITLE}`;

export default ({ description, image, title }) => ({
  title,

  meta: [
    metaTag({
      property: 'og:title',
      content: title,
    }),

    description &&
      metaTag({
        property: 'og:description',
        content: truncate(description, META_DESCRIPTION_MAX_LENGTH),
      }),

    description &&
      metaTag({
        name: 'description',
        content: truncate(description, META_DESCRIPTION_MAX_LENGTH),
      }),

    metaTag({
      name: 'twitter:card',
      content: image ? 'summary_large_image' : 'summary',
    }),

    ...(image ? openGraphImageMeta(image) : []),
  ].filter(Boolean),
});
