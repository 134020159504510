//
//
//
//
//
//
//
//
//
//
//
//

import { metaTag } from '~/assets/js/head';

export default {
  head() {
    const { htmlAttrs, link, meta, ...rest } = this.$nuxtI18nHead({
      addSeoAttributes: true,
    });
    const { href } = link.find(({ rel }) => rel === 'canonical');
    const ogUrl = metaTag({
      property: 'og:url',
      content: `${process.env.SITE_URL}${href.substring(1)}`,
    });

    return {
      htmlAttrs: {
        ...htmlAttrs,

        class: [process.server && 'no-js', 'layout', 'layout--shop'].filter(
          Boolean,
        ),
      },

      link: link.map(({ href, ...rest }) => ({
        ...rest,

        href: `${process.env.SITE_URL}${href.substring(1)}`,
      })),

      meta: [...meta, ogUrl],

      ...(process.server ? { script: this.$critical() } : {}),

      ...rest,
    };
  },
};
