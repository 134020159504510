
export default {
  functional: true,

  render(createElement, context) {
    const className = 'c-base-clickable';
    const {
      children,
      data: { attrs = {}, props = {}, ...data },
    } = context;
    const propsAndAttrs = {
      ...attrs,
      ...props,
    };
    let tag = 'button';

    if (propsAndAttrs.to) {
      tag = 'NuxtLink';
    } else if (propsAndAttrs.href) {
      tag = 'a';

      if (propsAndAttrs.target === '_blank') {
        propsAndAttrs.rel = 'noopener';
      }
    }

    return createElement(
      tag,
      {
        ...data,

        class: [data.class, className],

        attrs: {
          ...(tag === 'button' ? { type: 'button' } : {}),

          ...propsAndAttrs,
        },
      },
      children,
    );
  },
};
