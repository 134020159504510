
import BaseClickable from '~/components/BaseClickable.vue';

export default {
  functional: true,

  components: {
    BaseClickable,
  },

  render(createElement, { children, data }) {
    return createElement(
      'BaseClickable',
      {
        ...data,

        class: [data.class, 'c-primary-button'],
      },
      children,
    );
  },
};
