import { trackPageview } from '~/assets/js/analytics';

export default ({ app: { router } }) => {
  router.afterEach((to) => {
    const url = `${process.env.SITE_URL}${to.path.substring(1)}`.replace(
      /\/?$/,
      '/',
    );

    trackPageview(url);
  });
};
