import { render, staticRenderFns } from "./ShopHeader.vue?vue&type=template&id=6a9e1d22&"
import script from "./ShopHeader.vue?vue&type=script&lang=js&"
export * from "./ShopHeader.vue?vue&type=script&lang=js&"
import style0 from "./ShopHeader.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseClickable: require('/home/runner/work/livelinor.dk/livelinor.dk/site/components/BaseClickable.vue').default,ShopNavigation: require('/home/runner/work/livelinor.dk/livelinor.dk/site/components/ShopNavigation.vue').default})
