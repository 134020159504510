//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { capitalCase, sentenceCase } from 'change-case';

import MinusIcon from '~/assets/img/icons/icon-minus.svg';
import PlusIcon from '~/assets/img/icons/icon-plus.svg';

let uid = 0;

export default {
  filters: {
    capitalCase,
    sentenceCase,
  },

  components: {
    MinusIcon,
    PlusIcon,
  },

  props: {
    value: {
      // NOTE: string covers blank value
      type: [Number, String],

      default: 0,
    },

    min: {
      type: Number,

      required: true,
    },

    max: {
      type: Number,

      required: true,
    },

    disabled: {
      type: Boolean,

      default: false,
    },

    label: {
      type: String,

      default: null,
    },
  },

  data: () => ({ uid }),

  computed: {
    isDecrementButtonDisabled() {
      return this.value <= this.min;
    },

    isIncrementButtonDisabled() {
      return this.max - this.value === 0;
    },

    inputId() {
      return `quantity-input-${this.uid}`;
    },
  },

  beforeCreate() {
    uid += 1;
  },

  methods: {
    handleInput(event) {
      const { value } = event.target;

      if (value === '') {
        return;
      }

      const parsedValue = parseInt(value);

      if (Number.isNaN(parsedValue)) {
        return;
      }

      const clampedValue = Math.max(Math.min(parsedValue, this.max), this.min);

      this.$emit('input', clampedValue);

      this.$nextTick(() => {
        this.$refs.input.value = this.value;
      });
    },

    handleBlur(event) {
      const { value } = event.target;

      if (value === '') {
        this.$refs.input.value = this.value;
      }
    },

    handleDecrementButtonClick() {
      const { input } = this.$refs;

      input.stepDown();

      this.$emit('input', input.value);
    },

    handleIncrementButtonClick() {
      const { input } = this.$refs;

      input.stepUp();

      this.$emit('input', input.value);
    },
  },
};
