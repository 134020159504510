export const observeMediaQueries = (breakpoints, callback) =>
  Object.entries(breakpoints).reduce((acc, [name, { em }]) => {
    const mediaQueryList = matchMedia(`(min-width: ${em}em)`);
    const handler = (event) => callback(name, event);

    if ('addEventListener' in mediaQueryList) {
      mediaQueryList.addEventListener('change', handler);
    } else {
      mediaQueryList.addListener(handler);
    }

    handler(mediaQueryList);

    return {
      ...acc,

      [name]: [
        mediaQueryList,
        () => {
          if ('removeEventListener' in mediaQueryList) {
            mediaQueryList.removeEventListener('change', handler);
          } else {
            mediaQueryList.removeListener(handler);
          }
        },
      ],
    };
  }, {});
