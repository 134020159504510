import {
  addCheckoutLineItems,
  createCheckout,
  fetchCheckout,
  removeCheckoutLineItems,
  updateCheckoutLineItems,
} from '~/assets/js/services/shopify';

const CHECKOUT_ID_LOCAL_STORAGE_KEY_NAME = 'liv-elinor-shop-checkout-id';

export const state = () => ({
  isLoading: false,

  checkout: {
    completedAt: null,
    id: null,
    lineItems: [],
    ready: false,
    totalPrice: {},
    webUrl: null,
  },
});

export const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setCheckout(state, checkout) {
    state.checkout = { ...state.checkout, ...checkout };
  },
};

export const getters = {
  totalItemsInCart: ({ checkout: { lineItems } }) =>
    lineItems.reduce((total, { quantity }) => total + quantity, 0),

  isCheckoutActive: ({ checkout: { completedAt, id } }) =>
    id !== null && completedAt === null,
};

export const actions = {
  async addLineItem({ commit, dispatch, getters, state }, lineItem) {
    if (!getters.isCheckoutActive) {
      dispatch('createCheckout', lineItem);

      return;
    }

    commit('setIsLoading', true);

    const checkout = await addCheckoutLineItems(state.checkout.id, [lineItem]);

    commit('setCheckout', checkout);
    commit('setIsLoading', false);
  },

  async removeLineItem({ commit, state }, lineItemId) {
    commit('setIsLoading', true);

    const checkout = await removeCheckoutLineItems(state.checkout.id, [
      lineItemId,
    ]);

    commit('setCheckout', checkout);
    commit('setIsLoading', false);
  },

  async updateLineItem({ commit, state }, lineItem) {
    commit('setIsLoading', true);

    const checkout = await updateCheckoutLineItems(state.checkout.id, [
      lineItem,
    ]);

    commit('setCheckout', checkout);
    commit('setIsLoading', false);
  },

  async createCheckout({ commit, dispatch }, lineItem) {
    commit('setIsLoading', true);

    const checkout = await createCheckout(lineItem);

    commit('setCheckout', checkout);
    commit('setIsLoading', false);

    dispatch('saveCheckoutId');
  },

  async fetchCheckout({ commit }, checkoutId) {
    commit('setIsLoading', true);

    const checkout = await fetchCheckout(checkoutId);

    if (checkout.completedAt === null) {
      commit('setCheckout', {
        ...checkout,

        // NOTE: filter out variants that have been updated or removed from Shopify
        lineItems: checkout.lineItems.filter(({ variant }) => variant !== null),
      });
    }

    commit('setIsLoading', false);
  },

  async restoreCheckout({ dispatch }) {
    let checkoutId;

    try {
      checkoutId = localStorage.getItem(CHECKOUT_ID_LOCAL_STORAGE_KEY_NAME);
    } catch (error) {
      console.error(error);
    }

    if (checkoutId === null) {
      return;
    }

    await dispatch('fetchCheckout', checkoutId);
  },

  clearCheckout({ commit }) {
    commit('setCheckout', null);

    try {
      localStorage.removeItem(CHECKOUT_ID_LOCAL_STORAGE_KEY_NAME);
    } catch (error) {
      console.error(error);
    }
  },

  saveCheckoutId({ state }) {
    try {
      localStorage.setItem(
        CHECKOUT_ID_LOCAL_STORAGE_KEY_NAME,
        state.checkout.id,
      );
    } catch (error) {
      console.error(error);
    }
  },
};
