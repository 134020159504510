import {
  PRIMARY_COLLECTION_HANDLES,
  SHOPIFY_DEFAULT_VARIANT_TITLE,
} from '~/assets/js/constants';

export const parseDate = (dateString) => {
  const date = new Date(dateString);

  return {
    iso8601: date.toISOString(),
    pretty: dateString.split('-')[0],
  };
};

export const sortByOrderAndKey = (order, key) => (a, b) =>
  order.indexOf(a[key]) > order.indexOf(b[key]) ? 1 : -1;

export const sanityBlocksToPlainText = (blocks) =>
  blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }

      return block.children.map((child) => child.text).join('');
    })
    .join('\n\n');

export const truncate = (string = '', maximumLength = string.length) =>
  string.substr(0, maximumLength);

export const getPrimaryCollection = (collections) =>
  collections.find(({ handle }) => PRIMARY_COLLECTION_HANDLES.includes(handle));

export const getProductVariantTitle = (productTitle, variantTitle) =>
  variantTitle === SHOPIFY_DEFAULT_VARIANT_TITLE
    ? productTitle
    : `${productTitle} (${variantTitle})`;
