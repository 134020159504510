const handleError = (error) => {
  let Sentry = null;

  if (process.client || typeof window !== 'undefined') {
    ({ Sentry } = window);
  }

  if (error.isAxiosError && error.response) {
    const {
      status,
      statusText,
      config: { baseURL, url, method, headers },
    } = error.response;
    const errorMessage = `${status} ${statusText}`;
    const errorInfo = { baseURL, url, method, headers };

    if (process.env.NODE_ENV === 'production' && Sentry) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);

        Sentry.captureMessage(errorMessage);
      });
    }

    console.error(errorMessage, errorInfo);

    return;
  }

  if (process.env.NODE_ENV === 'production' && Sentry) {
    Sentry.captureException(error);
  }

  console.error(error);
};

export default (
  axios,
  { STOREFRONT_ACCESS_TOKEN, STOREFRONT_API_URL },
) => async ({ query, variables }) => {
  try {
    const {
      data: { data = {}, errors = [] },
    } = await axios.post(
      STOREFRONT_API_URL,
      { query, variables },
      {
        headers: {
          'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
        },
      },
    );

    if (errors.length) {
      throw new Error(
        `GraphQL Error(s): ${errors.map(({ message }) => message).join(', ')}`,
      );
    }

    return data;
  } catch (error) {
    handleError(error);
  }
};
