//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlockContent from 'sanity-blocks-vue-component';
import { sentenceCase } from 'change-case';

import ArrowIcon from '~/assets/img/icons/icon-arrow.svg';
import BigMouth from '~/assets/img/big-mouth.svg';
// payment provider icons
import AmexIcon from '~/assets/img/icons/icon-amex.svg';
import ApplePayIcon from '~/assets/img/icons/icon-apple-pay.svg';
import GooglePayIcon from '~/assets/img/icons/icon-google-pay.svg';
import MaestroIcon from '~/assets/img/icons/icon-maestro.svg';
import MastercardIcon from '~/assets/img/icons/icon-mastercard.svg';
import VisaIcon from '~/assets/img/icons/icon-visa.svg';

export default {
  filters: {
    sentenceCase,
  },

  components: {
    BlockContent,

    ArrowIcon,
    BigMouth,
    AmexIcon,
    ApplePayIcon,
    GooglePayIcon,
    MaestroIcon,
    MastercardIcon,
    VisaIcon,
  },

  data() {
    return this.$store.state.footer;
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    scrollToTop() {
      const htmlEl = document.documentElement;
      const isSmoothScrollSupported = 'scrollBehavior' in htmlEl.style;

      const options = {
        top: 0,
        left: 0,
        behavior: 'smooth',
      };

      if (isSmoothScrollSupported) {
        htmlEl.scrollTo(options);

        return;
      }

      const { left: x, top: y } = options;

      htmlEl.scrollTo(x, y);
    },
  },
};
