export const DANISH_LOCALE_CODE = 'da-dk';
export const ENGLISH_LOCALE_CODE = 'en';

export const DEFAULT_LOCALE_CODE = ENGLISH_LOCALE_CODE;

export const ENGLISH_LOCALE = {
  code: ENGLISH_LOCALE_CODE,
  iso: 'en-GB',
  dir: 'ltr',
  label: 'English',
  sanityCode: 'en',
};

export const DANISH_LOCALE = {
  code: DANISH_LOCALE_CODE,
  iso: 'da-DK',
  dir: 'ltr',
  label: 'Dansk',
  sanityCode: 'da_DK',
};

const LOCALES = [ENGLISH_LOCALE, DANISH_LOCALE];

export const toSanityLocale = (nuxtCode) => {
  const locale = LOCALES.find(({ code }) => code === nuxtCode);

  if (locale) {
    return locale.sanityCode;
  }

  throw new Error(`Sanity locale mapping missing: ${nuxtCode}`);
};

export const toNuxtLocale = (sanityCode) => {
  const locale = LOCALES.find(({ sanityCode: code }) => code === sanityCode);

  if (locale) {
    return locale.code;
  }

  throw new Error(`Nuxt locale mapping missing: ${sanityCode}`);
};

// NOTE: only exposing English for now
export default [ENGLISH_LOCALE];
